document.addEventListener("DOMContentLoaded", function () {
    function toggleDropdown(event) {
        event.preventDefault();

        const dropdown = this.closest(".ahw-button__dropdown")?.querySelector(".ahw-dropdown");

        if (dropdown) {
            dropdown.classList.toggle("ahw-dropdown--open");
        }
    }

    function closeDropdownOnClickOutside(event) {
        document.querySelectorAll(".ahw-dropdown.ahw-dropdown--open").forEach(openDropdown => {
            if (!openDropdown.contains(event.target) && !event.target.closest("[data-dropdown]")) {
                openDropdown.classList.remove("ahw-dropdown--open");
            }
        });
    }

    document.querySelectorAll("[data-dropdown]").forEach(button => {
        ["click", "touchstart"].forEach(eventType => {
            button.addEventListener(eventType, toggleDropdown, { passive:true });
        });
    });

    document.addEventListener("click", closeDropdownOnClickOutside, { passive:true });
});