document.addEventListener("click", function (event) {
    // Prüfen, ob der geklickte Button das Attribut data-toggle besitzt
    const button = event.target.closest('[data-toggle]');
    if (!button) return;

    // Wert von data-toggle des Buttons abrufen
    const toggleTarget = button.getAttribute('data-toggle');

    // Suchen nach dem Element mit dem passenden data-toggle-target-Wert
    const targetElement = document.querySelector(`[data-toggle-target="${toggleTarget}"]`);

    if (targetElement) {
        // Klasse "ahw-toggle--collapsed" toggeln
        targetElement.classList.toggle("ahw-toggle--collapsed");

        const buttonText = button.querySelector(".ahw-button__text");

        if (buttonText.textContent === "Ausklappen") {
            buttonText.textContent = "Zuklappen";
        } else {
            buttonText.textContent = "Ausklappen";
        }
    }

    // Klassen für das Icon toggeln
    const buttonIcon = button.querySelector(".ahw-button__icon");
    if (buttonIcon) {
        buttonIcon.classList.toggle("ahw-button__icon--angled");
    }
});