document.addEventListener("play", function (event) {
    // Prüfen, ob das Ziel ein Video mit data-videoplayer="true" ist
    const target = event.target;
    if (target.tagName === "VIDEO" && target.dataset.videoplayer === "true") {
        // Alle anderen Videos pausieren
        const videos = document.querySelectorAll('video[data-videoplayer="true"]');
        videos.forEach((video) => {
            if (video !== target && !video.paused) {
                video.pause();
            }
        });
    }
}, true); // 'true' sorgt dafür, dass das Event im Capturing-Phase abgegriffen wird