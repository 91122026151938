// Funktion: Dynamisches Offcanvas erstellen
function createOffcanvas(title) {
    // Offcanvas Header mit Titel
    const offcanvasHeaderHTML = `
        <div class="ahw-offcanvas__header">
            <button class="ahw-offcanvas__close ahw-button ahw-button--secondary"
                    title="Schließen">
                <div class="ahw-button__icon ahw-button__icon--close ahw-button__icon--reduced"></div>
                <div class="ahw-button__text">Schließen</div>
            </button>
            <div class="ahw-offcanvas__header-row">
                <div class="ahw-heading">
                    <div class="ahw-heading__headline">
                        ${title}
                    </div>
                </div>
                <a class="ahw-button"
                   href="mailto:info@ahw-tools.de"
                   title="E-Mail schreiben">
                    <div class="ahw-button__icon ahw-button__icon--mail"></div>
                    <div class="ahw-button__text">Kontakt</div>
                </a>
            </div>
        </div>
    `;

    // Offcanvas erstellen
    const offcanvas = document.createElement('div');
    offcanvas.classList.add('ahw-offcanvas');

    // Header hinzufügen
    const offcanvasHeader = document.createElement('div');
    offcanvasHeader.innerHTML = offcanvasHeaderHTML;

    // Offcanvas Body erstellen
    const offcanvasBody = document.createElement('div');
    offcanvasBody.classList.add('ahw-offcanvas__body');
    offcanvasBody.textContent = 'Lädt...'; // Standard-Ladeanzeige

    // Header und Body ins Offcanvas integrieren
    offcanvas.appendChild(offcanvasHeader.firstElementChild);
    offcanvas.appendChild(offcanvasBody);

    // Overlay erstellen
    const overlay = document.createElement('div');
    overlay.classList.add('ahw-offcanvas__overlay');

    // Offcanvas und Overlay zum Dokument hinzufügen
    document.body.appendChild(overlay);
    document.body.appendChild(offcanvas);

    // Schließen-Button referenzieren
    const closeOffcanvasButton = offcanvas.querySelector('.ahw-offcanvas__close');

    return { offcanvas, overlay, offcanvasBody, closeOffcanvasButton };
}

// Funktion: Offcanvas öffnen und PHP-Inhalt laden
async function openOffcanvas(elements, phpFile) {
    const { offcanvas, overlay, offcanvasBody } = elements;

    offcanvasBody.textContent = 'Lädt...';

    try {
        // PHP-Datei laden
        const response = await fetch(phpFile);
        if (!response.ok) {
            throw new Error(`Fehler beim Laden der Datei: ${phpFile}`);
        }

        // PHP-Inhalt in das Offcanvas laden
        const content = await response.text();
        offcanvasBody.innerHTML = content;
    } catch (error) {
        offcanvasBody.textContent = `Fehler: ${error.message}`;
    }

    // Body Scroll deaktivieren
    document.body.classList.add('ahw-noscroll');

    // Offcanvas und Overlay sichtbar machen
    overlay.classList.add('ahw-overlay--open');
    offcanvas.classList.add('ahw-offcanvas--open');

    // Event-Listener für das Schließen
    overlay.addEventListener('click', closeOffcanvas);
    elements.closeOffcanvasButton.addEventListener('click', closeOffcanvas);

    function closeOffcanvas() {
        offcanvas.classList.remove('ahw-offcanvas--open');
        overlay.classList.remove('ahw-overlay--open');
        document.body.classList.remove('ahw-noscroll');
        document.body.removeChild(offcanvas);
        document.body.removeChild(overlay);
    }
}

// Event-Listener für alle Buttons mit data-offcanvas=true
document.querySelectorAll('button[data-offcanvas=true]').forEach(button => {
    button.addEventListener('click', () => {
        const title = button.dataset.title || 'Kein Titel'; // Titel aus data-title
        const phpFile = button.dataset.content; // PHP-Datei aus data-content

        if (phpFile) {
            const offcanvasElements = createOffcanvas(title); // Offcanvas mit dynamischem Titel erstellen
            openOffcanvas(offcanvasElements, phpFile); // Offcanvas öffnen und dynamischen Inhalt laden
        } else {
            alert('Keine PHP-Datei angegeben.');
        }
    });
});