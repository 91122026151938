// Funktion: Dynamisches Modal erstellen
function createModal(title) {
    // Auflösen des Headers mit dem Titel
    const modalHeaderHTML = `
        <div class="ahw-modal__header">
            <div class="ahw-heading">
                <div class="ahw-heading__headline">
                    ${title}
                </div>
          </div>
          <button class="ahw-modal__close ahw-button ahw-button--secondary"
                  title="Schließen">
                <div class="ahw-button__icon ahw-button__icon--close ahw-button__icon--reduced"></div>
                <div class="ahw-button__text">Schließen</div>
            </button>
        </div>
    `;

    // Erstelle Overlay
    const modalOverlay = document.createElement('div');
    modalOverlay.classList.add('ahw-modal__overlay');

    // Erstelle Modal
    const modal = document.createElement('div');
    modal.classList.add('ahw-modal');

    // Header hinzufügen
    const modalHeader = document.createElement('div');
    modalHeader.innerHTML = modalHeaderHTML;

    // Modal-Body erstellen
    const modalBody = document.createElement('div');
    modalBody.classList.add('ahw-modal__body');
    modalBody.textContent = 'Lädt...'; // Default-Ladeanzeige

    // Header und Body ins Modal hinzufügen
    modal.appendChild(modalHeader.firstElementChild);
    modal.appendChild(modalBody);

    // Anhängen zum Dokument-Body
    document.body.appendChild(modalOverlay);
    document.body.appendChild(modal);

    // Den Schließen-Button aus dem Header referenzieren
    const closeModalButton = modal.querySelector('.ahw-modal__close');

    return { modal, modalOverlay, modalBody, closeModalButton };
}

// Funktion: Modal öffnen und PHP-Inhalt laden
async function openModal(elements, phpFile) {
    const { modal, modalOverlay, modalBody } = elements;

    modalBody.textContent = 'Lädt...';

    try {
        // PHP-Datei abrufen
        const response = await fetch(phpFile);
        if (!response.ok) {
            throw new Error(`Fehler beim Laden der Datei: ${phpFile}`);
        }

        // PHP-Inhalt ins Modal laden
        const content = await response.text();
        modalBody.innerHTML = content;
    } catch (error) {
        modalBody.textContent = `Fehler: ${error.message}`;
    }

    // Body Scroll deaktivieren
    document.body.classList.add('ahw-noscroll');

    // Modal und Overlay sichtbar machen
    modalOverlay.classList.add('ahw-modal__overlay--open');
    modal.classList.add('ahw-modal--open');

    // Event-Listener für das Schließen
    modalOverlay.addEventListener('click', closeModal);
    elements.closeModalButton.addEventListener('click', closeModal);

    function closeModal() {
        modal.classList.remove('open');
        modalOverlay.classList.remove('open');
        document.body.classList.remove('ahw-noscroll');
        document.body.removeChild(modal);
        document.body.removeChild(modalOverlay);
    }
}

// Event-Listener für alle Buttons mit data-modal=true
document.querySelectorAll('button[data-modal=true]').forEach(button => {
    button.addEventListener('click', () => {
        const title = button.dataset.title || 'Kein Titel'; // Titel aus data-title
        const phpFile = button.dataset.content; // PHP-Datei aus data-content

        if (phpFile) {
            const modalElements = createModal(title); // Modal mit dynamischem Titel erstellen
            openModal(modalElements, phpFile); // Modal öffnen mit dynamischem Inhalt
        } else {
            alert('Keine PHP-Datei angegeben.');
        }
    });
});