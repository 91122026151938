class Tabs {
    constructor(selector) {
        this.selector = selector; // Der Tab-Container-Selektor
        this.init();
    }

    init() {
        // Event-Delegation, um auch dynamisch hinzugefügte Tabs zu unterstützen
        document.addEventListener('click', (event) => {
            const button = event.target.closest('[data-tabs-link]');
            if (button && button.closest(this.selector)) {
                // Den passenden Tab-Container finden
                const tabContainer = button.closest(this.selector);
                this.activateTab(button, tabContainer);
            }
        });
    }

    activateTab(button, tabContainer) {
        const targetId = button.dataset.tabsLink; // Die ID des Ziels auslesen

        if (!targetId || !tabContainer) return; // Sicherheit: Nichts tun, falls etwas fehlt

        // 0. Pausiere alle laufenden Videos
        this.pauseAllVideos();

        // 1. Alle Tabs und Inhalte *innerhalb des aktuellen Containers* finden
        const allButtons = tabContainer.querySelectorAll('[data-tabs-link]');
        const allContents = tabContainer.querySelectorAll('[data-tabs-target]');

        // 2. Vorherige aktive Tabs und Inhalte deaktivieren
        allButtons.forEach((btn) => btn.classList.remove('ahw-tabs__link--active'));
        allContents.forEach((content) => content.classList.remove('ahw-tabs__content--active'));

        // 3. Den geklickten Tab aktivieren
        button.classList.add('ahw-tabs__link--active');

        // 4. Das verknüpfte Tab-Inhaltselement aktivieren
        const targetContent = tabContainer.querySelector(`[data-tabs-target="${targetId}"]`);
        if (targetContent) {
            targetContent.classList.add('ahw-tabs__content--active');
        }
    }

    pauseAllVideos() {
        // Video-Elemente mit `data-videoplayer="true"` finden und pausieren
        const videos = document.querySelectorAll('video[data-videoplayer="true"]');
        videos.forEach((video) => {
            if (!video.paused) {
                video.pause();
            }
        });
    }
}

// Die Tabs initialisieren, wenn die Seite geladen wurde
document.addEventListener('DOMContentLoaded', () => {
    new Tabs('[data-tabs="true"]'); // Funktioniert für jeden Container mit `data-tabs="true"`
});