document.addEventListener('DOMContentLoaded', () => {
    const baseUrl = `${window.location.origin}/`; // Deinen `base_url` einfügen (vom PHP-Backend)

    // Alle Links mit `data-smoothscroll="true"` auswählen
    const smoothScrollLinks = document.querySelectorAll('a[data-smoothscroll="true"]');

    // Eventlistener hinzufügen
    smoothScrollLinks.forEach(link => {
        link.addEventListener('click', (event) => {
            const href = link.getAttribute('href');
            const url = new URL(href, baseUrl); // Ganzen Link analysieren (inkl. Hostname, Pfad, etc.)

            // Prüfen, ob URL dieselbe Domain hat und ein Anker existiert
            if (url.hostname === window.location.hostname && url.hash) {
                const targetId = url.hash; // Z.B. `#philosophie`
                const targetElement = document.querySelector(targetId);

                if (targetElement) {
                    // Anker wurde gefunden, Smooth Scroll ausführen
                    event.preventDefault(); // Standardverhalten verhindern
                    targetElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }
        });
    });
});